import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFRenderer = ({ fileUrl, modalHeaderText, openPdfModal, closePdfModal }) => {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Modal isOpen={openPdfModal} toggle={closePdfModal} size='xl'>
      <ModalHeader toggle={closePdfModal}>{modalHeaderText}</ModalHeader>
      <ModalBody>
        <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js'>
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      </ModalBody>
    </Modal>
  );
}

export default PDFRenderer;
