import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Button, Label } from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";
import { reasonOptions, typeOptions } from "../../../../Components/constants/rgrform";

import { 
    getReturn,
    getReturnDetails,
    getCustomerInformation,
    getReturnDetailsExtra as onGetReturnDetailsExtra, 
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

const ReturnHistoryDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [returnInfo, setReturnInfo] = useState({});
    
    const { returnInvoice, isReturnSuccess, returnDetails, returnDetailsExtra, isReturnDetailsSuccess, userInfo, isUserInfoSuccess, isPostReturnInvoiceApprovalSuccess, isPostReturnInvoiceApprovalFail, returnsError, customerError } = useSelector((state) => ({
        returnInvoice: state.Returns.returnInvoice,
        isReturnSuccess: state.Returns.isReturnSuccess,
        returnDetails: state.Returns.returnDetails,
        returnDetailsExtra: state.Returns.returnDetailsExtra,
        isReturnDetailsSuccess: state.Returns.isReturnDetailsSuccess,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        isPostReturnInvoiceApprovalSuccess: state.Returns.isPostReturnInvoiceApprovalSuccess,
        isPostReturnInvoiceApprovalFail: state.Returns.isPostReturnInvoiceApprovalFail,
        returnsError: state.Returns.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let return_id = location.pathname.split('/')[3];
        dispatch(getReturn(return_id));
        dispatch(getReturnDetails(return_id));
        dispatch(onGetReturnDetailsExtra(return_id));
    }, [dispatch, location])

    useEffect(() => {
        setReturnInfo(returnInvoice);
        if (returnDetails && returnDetails.length !== 0) dispatch(getCustomerInformation(returnInvoice.username));
    }, [dispatch, returnDetails, returnInvoice])

    useEffect(() => {
        if(isPostReturnInvoiceApprovalSuccess) {
            let return_id = location.pathname.split('/')[3];
            dispatch(getReturn(return_id));
            dispatch(getReturnDetails(return_id)); 
        }

        if (isPostReturnInvoiceApprovalFail) alert("Posting accept/reject failed");
    }, [dispatch, location, isPostReturnInvoiceApprovalSuccess, isPostReturnInvoiceApprovalFail])

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    if ((typeof returnsError === 'string' && returnsError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | Return History | Max Advanced Brakes - Prozone`;

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Return History Details" pageTitle="Return History" linkTo={`/history/return-history`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4 border-dark">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal"> Return No: </span>{" "}
                                                <span id="legal-register-no">{returnInfo.id}</span>
                                            </h6>
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (
                                    <Row className="g-3">
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Return No </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{returnInfo.id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Date </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{returnInfo.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            {(returnInfo.ship_method) ? (
                                                <>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">Shipping Method</p>
                                                    <span id="total-amount">{returnInfo.ship_method}</span>
                                                </>
                                            ) : (null)}
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Return Invoice Status </p>
                                            <span className="badge badge-soft-info fs-11">{returnInfo.status}</span>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            {(returnInfo.reference_return_invoice_id) ? (
                                                <>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold"> Reference Return Invoice Id </p>
                                                    <span id="total-amount">{returnInfo.reference_return_invoice_id}</span>
                                                </>
                                            ) : (null)}
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed border-dark">
                                <Row className="g-3">
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length !== 0) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3">Shipping Address</h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.company_name}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1">{userInfo.state}, {userInfo.country} - {userInfo.zip}</p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4">
                                <div className="table-responsive">
                                    <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>#</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">MAX-Product</th>
                                                <th scope="col">Order Id</th>
                                                <th scope="col">Created</th>
                                                <th scope="col">Requested</th>
                                                <th scope="col">Returned</th>
                                                <th scope="col">Reason</th>
                                                <th scope="col">Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isReturnDetailsSuccess && returnDetails.length !== 0) ? (
                                                returnDetails.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.product_query}</td>
                                                            <td>
                                                                <span className="fw-medium">{item.product}</span>
                                                                <p className="text-muted mb-0"></p>
                                                            </td>
                                                            <td>{item.orderid}</td>
                                                            <td>
                                                                {(item.invoice_created) ? (item.invoice_created.slice(0, 10)) : 
                                                                (returnInvoice.date_created) ? (returnInvoice.date_created.slice(0, 10)) : 
                                                                (null)}
                                                            </td>
                                                            <td>{item.qty_to_return}</td>
                                                            <td>{item.qty_returned}</td>
                                                            <td>
                                                                {(item.type) ? (
                                                                    typeOptions.find((type) => type.value === item.type).label
                                                                ) : (null)}
                                                            </td>
                                                            <td>
                                                                {(item.reason) ? (
                                                                    reasonOptions.find((reason) => reason.value === item.reason).label
                                                                ) : (null)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (null)}
                                        </tbody>
                                    </Table>
                                </div>
                                {(returnDetailsExtra && returnDetailsExtra.length) ? (
                                    <>
                                        <br />
                                        <div className="table-responsive">
                                            <Label>Adjustments:</Label>
                                            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr className="table-active">
                                                        <th scope="col" style={{ width: "50px" }}>#</th>
                                                        <th scope="col">Product</th>
                                                        <th scope="col">Returned</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {returnDetailsExtra.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.product}</td>
                                                                <td>{item.qty_returned}</td>
                                                            </tr>
                                                        )    
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>
                                ) : (null)}
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    {/* <Button onClick={printInvoice} className="btn btn-soft-primary">
                                        <i className="ri-printer-line align-bottom me-1"></i> Print
                                    </Button>
                                    <Button color={'primary'}>
                                        <i className="ri-download-2-line align-bottom me-1"></i> Download
                                    </Button> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ReturnHistoryDetails;
