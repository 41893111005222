import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row, FormGroup, Label, Input, FormText,} from "reactstrap";
import classnames from "classnames";
import * as XLSX from 'xlsx';
//import Components
import { useDispatch, useSelector } from 'react-redux';

import {
    getGlobalPrices,
    updateGlobalPrices,
} from "../../store/actions";

function GlobalPricing() {
    const dispatch = useDispatch();
    const [errorStatus, setErrorStatus] = useState();
    const [uploadPrice, setUploadPrice] = useState([]);
    const { prices, isPricesDownloaded, isGlobalPricesUpdated } = useSelector(state => ({
        prices: state.GlobalSettings.prices,
        isPricesDownloaded: state.GlobalSettings.isPricesDownloaded,
        isGlobalPricesUpdated: state.GlobalSettings.isGlobalPricesUpdated
    }));

    useEffect(() => {
        if (isGlobalPricesUpdated) alert("Prices Updated")
    }, [isGlobalPricesUpdated])

    function handleDownloadPrices() {
        dispatch(getGlobalPrices());
    }

    function handleUpload(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        const file_size = event.target.files[0].size;
        if (file_size/(1024*1024) >= 10) {
            alert("file size must not be greater than 10MB.")
            event.target.value = null;
            return;
        }

        let reader = new FileReader();
        reader.onload = function (evt) {
            let data = evt.target.result;
            let lines = [];

            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type: 'binary' });
                let parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
                let headers = parsed[0].toString().split(',');

                for (let i = 1; i < parsed.length; i++) {
                    let fileData = parsed[i].toString().split(',');
                    if (fileData.length === headers.length) {
                        let temp = [];
                        for (let j = 0; j < headers.length; j++) {
                            temp.push({ [headers[j]]: fileData[j] });
                        }
                        temp = Object.assign({}, ...temp);
                        lines.push(temp)
                    }
                }
            } else {
                let allText = data.split(/\r\n|\n/);
                let headers = allText[0].split(',');

                for (let i = 1; i < allText.length; i++) {
                    let fileData = allText[i].split(',');
                    if (fileData.length === headers.length) {
                        let temp = [];
                        for (let j = 0; j < headers.length; j++) {
                            temp.push({ [headers[j]]: fileData[j] });
                        }
                        temp = Object.assign({}, ...temp);
                        lines.push(temp);
                    }
                }
            }
            setUploadPrice(lines);
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);

    }

    function handleSubmitPrices() {
        dispatch(updateGlobalPrices(uploadPrice));
    }

    useEffect(() => {
        if (isPricesDownloaded === true) {
            let csv = '';

            for (let i in prices[0]) {
                csv += i + ',';
            }
            csv += '\n';
            prices.forEach((item) => {
                for (let key in item) {
                    csv += item[key] + ',';
                }
                csv += "\n";
            });

            let hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Price.csv';
            hiddenElement.click();
        }
    }, [isPricesDownloaded]);

    return (
        <>
            <h4>Pricing Information</h4>
            <div>
                <Row xxl="auto" className="p-3">
                    <Col>
                        <Button type="button" color="warning" onClick={handleDownloadPrices}> Download Prices</Button>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <FormGroup>
                                <Label for="file">Upload New Pricing File</Label>
                                <Input type="file" name="pricefile" id="priceFile" onChange={(e) => handleUpload(e)} />
                                <FormText color="muted">
                                    This will update all item pricing, please upload via the template provided, maximum file size is 10MB.
                                </FormText>
                            </FormGroup>
                        </Row>
                        <Row xxl="auto" className="align-items-center justify-content-center">
                            <Col>
                                <Button type="button" color="warning" onClick={handleSubmitPrices}>Submit File</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}

export default GlobalPricing;
