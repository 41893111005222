import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";

import GlobalInput from "./GlobalInput";
import { useProfile } from "../Hooks/UserHooks";

import {
    getUsers as onGetUsers,
    postGlobalDiscount,
} from "../../store/actions";

function GlobalSettingsItemDiscounts({ users }) {
    const dispatch = useDispatch();
    const { userProfile } = useProfile()
    const [globalDiscount, setGlobalDiscount] = useState({ ONZ: 0, OCG: 0, SNZ: 0, SCB: 0, AFN: 0, CDM: 0, CDP: 0, MD: 0, BEARING: 0, CNZ: 0,TSU: 0, NCG: 0, WHB: 0 });
    const [globalDiscountUser, setGlobalDiscountUser] = useState('all');

    function getUserDiscountInfo(username) {
        setGlobalDiscountUser(username)
        let user = users.find((item) => item.username === username);
        if (username === 'all') {
            setGlobalDiscount({ ONZ: 0, OCG: 0, SNZ: 0, SCB: 0, AFN: 0, CDM: 0, CDP: 0, MD: 0, BEARING: 0, CNZ: 0, TSU: 0, NCG: 0, WHB: 0 })
        }
        setGlobalDiscount({ ONZ: user.ONZ, OCG: user.OCG, SNZ: user.SNZ, SCB: user.SCB, AFN: user.AFN, CDM: user.CDM, CDP: user.CDP, MD: user.MD, BEARING: user.bearing, CNZ: user.CNZ, TSU: user.TSU, NCG: user.NCG, WHB: user.WHB })
    }

    function handlePostDiscountDefault() {
        try {
            let param = {};
            if (globalDiscountUser !== 'all') {
                param = { ...globalDiscount, username: globalDiscountUser };
            } else {
                param = globalDiscount;
            }

            dispatch(postGlobalDiscount(param));
            alert('Discounts Updated');
            onGetUsers();
        } catch (error) {
            console.log("handlePostDiscountDefault Failed: " + error.message);
        }
    }

    return (
        <>
            <h4>Category Discounts</h4>
            <br></br>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Product Line Discount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormGroup className="p-3">
                                <Label for="customerSelect2">Select one or all Customer</Label>
                                <Input type="select" onChange={e => { getUserDiscountInfo(e.target.value) }}>
                                    <option value="all">All</option>
                                    {(users !== undefined && users !== null) ? (
                                        users.map((user, username) => <option key={username} value={user.username}>{user.company_name} ({user.firstname} {user.lastname})</option>)
                                    ) : (null)}
                                </Input>
                            </FormGroup></td>
                        <td>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'ONZ'} defaultValue={globalDiscount.ONZ} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'OCG'} defaultValue={globalDiscount.OCG} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'SNZ'} defaultValue={globalDiscount.SNZ} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'SCB'} defaultValue={globalDiscount.SCB} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'AFN'} defaultValue={globalDiscount.AFN} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CDM'} defaultValue={globalDiscount.CDM} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CDP'} defaultValue={globalDiscount.CDP} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'TSU'} defaultValue={globalDiscount.TSU} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'MD'} defaultValue={globalDiscount.MD} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'BEARING'} defaultValue={globalDiscount.BEARING} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CNZ'} defaultValue={globalDiscount.CNZ} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'NCG'} defaultValue={globalDiscount.NCG} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'WHB'} defaultValue={globalDiscount.WHB} disabled={(userProfile.access === 1) ? (false) : (true)} />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="text-center justify-content-center">
                <Button type="button" color="warning" onClick={handlePostDiscountDefault}>Update</Button>
            </div>
        </>
    )
}

export default GlobalSettingsItemDiscounts;
