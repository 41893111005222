import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";
import { Col, List, ListGroupItem, Row } from "reactstrap";

import { categoryAxle, productLineDescription, productLines } from "../../../Components/constants/vehicleDetails";

import ecat_to_max from "./utility";
import VehicleSearchAddToCart from "./VehicleSearchAddToCart";

import { 
    postProductsWholesaleInventory as onPostProductsWholesaleInventory,
} from "../../../store/actions";
import Loader from "../../Common/Loader";

function VehicleSearchProductList() {
    const dispatch = useDispatch();
    let { category } = useParams();
    let match = useRouteMatch('/products/vehicle-search/:year/:make/:model/:category');
    const [productsArr, setProductsArr] = useState([]);
    const [productsArrWithInv, setProductsArrWithInv] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { categories, productsInventory, postProductWholesaleInventorySuccess } = useSelector((state) => ({
        categories: state.Vehicle.categories,
        productsInventory: state.Vehicle.productsInventory,
        postProductWholesaleInventorySuccess: state.Vehicle.postProductWholesaleInventorySuccess,
    }));

    useEffect(() => {
        setIsLoading(true);
    }, [category]);

    useEffect(() => {
        setProductsArr([]);
        setProductsArrWithInv([]);
        if (match.isExact && categories.length) {
            let temp = ecat_to_max(categories);
            let tempNewArr = [];
            let axleUnique = {};
            let submodelUnique = {};
            let applicationNoteUnique = {};
            let originalUnique = {};
            let productLineDescriptionUnique = {};
            let displayNameUnique = {};
            let interchangeUnique = {};
            let itemTypeUnique = {};
            let itemTypeWithAxleUnique = {};

            temp = temp.filter((item) => item.maxnum.search("SYS") === -1 && item.maxnum.search("SYS") === -1)

            temp.forEach((item) => {
                submodelUnique[item.maxnum] += ', ' + (item.submodel || '');
                applicationNoteUnique[item.maxnum] += ', ' + (item.applicationnote || '');
                axleUnique[item.maxnum] = (item.axle || '');
                originalUnique[item.maxnum] = (item.original || '');
                productLineDescriptionUnique[item.maxnum] = (productLineDescription[item.maxnum.match(productLines)] || '');
                displayNameUnique[item.maxnum] = (item.displayname || '');
                interchangeUnique[item.maxnum] = (item.interchange || '');
                itemTypeUnique[item.maxnum] = (item.itemtype || '');
                itemTypeWithAxleUnique[item.maxnum] = (`${item.itemtype} ${item.axle}` || '');

                submodelUnique[item.maxnum] = submodelUnique[item.maxnum].replace('undefined', '');
                submodelUnique[item.maxnum] = submodelUnique[item.maxnum].split(',').filter((item) => item);
                submodelUnique[item.maxnum] = submodelUnique[item.maxnum].filter((submodel, index) => !submodelUnique[item.maxnum].includes(submodel, index + 1));

                applicationNoteUnique[item.maxnum] = applicationNoteUnique[item.maxnum].replace('undefined', '');
                applicationNoteUnique[item.maxnum] = applicationNoteUnique[item.maxnum].split(',').filter((item) => item);
                applicationNoteUnique[item.maxnum] = applicationNoteUnique[item.maxnum].filter((note, index) => !applicationNoteUnique[item.maxnum].includes(note, index + 1));
            })
            
            for (let key in displayNameUnique) {
                tempNewArr.push({ submodels: submodelUnique[key], applicationNotes: applicationNoteUnique[key], axle: axleUnique[key], original: originalUnique[key], productLine: productLineDescriptionUnique[key], displayName: displayNameUnique[key], interchange: interchangeUnique[key], itemtype: itemTypeUnique[key], itemTypeWithAxleUnique: itemTypeWithAxleUnique[key] }); 
            }

            // let productList = tempNewArr.map((item) => item.displayName)
            // dispatch(onPostProductArray(productList))

            tempNewArr = tempNewArr.filter((item) => item.itemTypeWithAxleUnique === match.params.category)
            setProductsArr(tempNewArr)
        }
    }, [dispatch, categories, category]);

    useEffect(() => {
        if (productsArr.length) {
            let products = productsArr.map((item) => ({ part: item.displayName }));
            dispatch(onPostProductsWholesaleInventory(products));
        }
    }, [dispatch, productsArr]);

    useEffect(() => {
        if (postProductWholesaleInventorySuccess && productsInventory.length) {
            let tempArr = productsArr;
            tempArr = tempArr.map((product) => {
                let inv = productsInventory.filter((productInv) => productInv.part.toString() === product.displayName);
                if (inv && inv[0]) product.forsale = inv[0].forsale;
                else product.forsale = 0
                return product;
            });
            setProductsArrWithInv(tempArr);
        }
    }, [postProductWholesaleInventorySuccess, productsInventory])

    function addToTempCart(qty, product) {
        if (Number(qty)) {
            let cart = JSON.parse(sessionStorage.getItem('cart') || "[]");
            let newCartItem = { product: product.displayName, pcode: product.displayName, qty: Number(qty) };

            let found = cart.find((item) => item.product === newCartItem.product)

            if (found) {
                cart.forEach((item) => {
                    if (item.product === newCartItem.product) item.qty += newCartItem.qty;
                    return item;
                })
            } else {
                cart.push(newCartItem);
            }

            sessionStorage.setItem('cart', JSON.stringify(cart));
            alert('Item added to cart');
        } else {
            alert('A quantity amount must be given');
        }
    }

    function isItemOriginalValid(itemOriginal){
        if(itemOriginal != null && itemOriginal.trim() != "") return true;
        return false; 
    }

    useEffect(() => {
        if(!productsArrWithInv.length) {
            setTimeout(function() {
                setIsLoading(false);
            }, 300);
        }
    }, [productsArrWithInv]);

    return (
        <>
        {
            (isLoading) ? (<Loader />) : 
            ((productsArrWithInv && productsArrWithInv.length) ? (
                productsArrWithInv.map((item, index) => {
                    return (
                        <ListGroupItem action key={index}>
                            <Row>
                                <Col>
                                    <strong>{isItemOriginalValid(item.original) ? item.original : item.interchange}</strong> {item.productLine}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <List>
                                        <li>{categoryAxle[item.axle]}</li>
                                        {(item.applicationNotes && item.applicationNotes.length) ? (
                                            item.applicationNotes.map((applicationnote, index) => (applicationnote.trim() !== '') ? <li key={index}>{applicationnote}</li> : (null))
                                        ) : (null)}
                                        {(item.submodels && item.submodels.length) ? (
                                            item.submodels.map((submodel, index) => (submodel.trim() !== '') ? (<li key={index}>{submodel}</li>) : (null))
                                        ) : (null)}
                                    </List>
                                </Col>
                            </Row>
                            {(item.forsale) ? (<VehicleSearchAddToCart product={item} addToTempCart={addToTempCart} />) : (null)}
                        </ListGroupItem>
                    )
                })
            ) : (null))
        }
        </>
    )
}

export default VehicleSearchProductList;
