import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Button, Input, Label } from "reactstrap";
import { Link, Prompt, Redirect, useHistory, useLocation } from "react-router-dom";
import Loader from "../../../../Components/Common/Loader";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";
import DispatchDetailsTableRows from "../../../../Components/Warehouse/Dispatch/DispatchDetailsTableRows";
import { downloadFiles } from "../../../../Components/Common/downloadFiles";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

import { 
    getInvoice as onGetInvoice,
    getInvoiceDetails as onGetInvoiceDetails, 
    getCustomerInformation as onGetCustomerInformation,
    postInvoiceDetailsProductQuantity as onPostInvoiceDetailsProductQuantity,
    postShipOrder as onPostShipOrder,
    postCancelOrder as onPostCancelOrder,
    downloadInvoicePdf as onDownloadInvoicePdf,
    downloadPackingListPdf as onDownloadPackingListPdf,
} from "../../../../store/actions";
import { provincesTaxOptions } from "../../../../Components/constants/invoiceDetails";

const DispatchDetails = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [tableInstance, setTableInstance] = useState(0);
    const [invoiceArr, setInvoiceArr] = useState([])
    const [numRows, setNumRows] = useState(null);
    const [shippedItems, setShippedItems] = useState([]);
    const [carrier, setCarrier] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [isBlocking, setIsBlocking] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    let location = useLocation();
    let history = useHistory();
    let rows = [];
    
    const { invoice, invoiceDetails, isInvoiceDetailsSuccess, userInfo, isUserInfoSuccess, isOrderShippedSuccess, isOrderShippedFalse, invoicePdf, isInvoicePdfDownloaded, packingListPdf, isPackingListPdfDownloaded, isOrderStatusChangeSuccess, isOrderStatusChangeFail, invoiceError, customerError } = useSelector((state) => ({
        invoice: state.Invoice.invoice,
        invoiceDetails: state.Invoice.invoiceDetails,
        isInvoiceDetailsSuccess: state.Invoice.isInvoiceDetailsSuccess,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        isOrderShippedSuccess: state.Invoice.isOrderShippedSuccess, 
        isOrderShippedFalse: state.Invoice.isOrderShippedFalse,
        invoicePdf: state.Invoice.invoicePdf,
        isInvoicePdfDownloaded: state.Invoice.isInvoicePdfDownloaded,
        packingListPdf: state.Invoice.packingListPdf,
        isPackingListPdfDownloaded: state.Invoice.isPackingListPdfDownloaded,
        isOrderStatusChangeSuccess: state.Invoice.isOrderStatusChangeSuccess,
        isOrderStatusChangeFail: state.Invoice.isOrderStatusChangeFail,
        invoiceError: state.Invoice.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let order_id = location.pathname.split('/')[3];
        dispatch(onGetInvoice(order_id));
        dispatch(onGetInvoiceDetails(order_id));
    }, [dispatch, location])

    useEffect(() => {
        setTableInstance(prev => prev + 1);

        setInvoiceArr(invoiceDetails);
        setNumRows(invoiceDetails.length);
    }, [invoiceDetails])

    useEffect(() => {
        if (invoice && Object.keys(invoice).length) dispatch(onGetCustomerInformation(invoice.username));
    }, [dispatch, invoice])

    useEffect(() => {
        let product = { data: [{ part: '' }] };
        let hasProperty = true;

        if (userProfile.access === 5 || !invoiceDetails.length) return;

        invoiceDetails.forEach((item) => {
            if (!Object.hasOwn(item, 'forsale')) {
                hasProperty = false;
            }
        })

        if (hasProperty) return;
        
        product.data = invoiceDetails.map((item) => {
            let tempMaxProduct = item.max_product;
            let tempNotMaxCode = item.product;
            let productToSearch = '';

            // TODO: make check more dynamic
            productToSearch = tempNotMaxCode;
            if (tempMaxProduct && tempMaxProduct.match(/^OCG|^8100/i)) {
                productToSearch = tempMaxProduct;
            }

            return { part: productToSearch }
        });
        
        dispatch(onPostInvoiceDetailsProductQuantity(product.data));
    }, [invoiceDetails, dispatch, userProfile]);

    useEffect(() => {
        if (isOrderShippedSuccess) {
            let order_id = location.pathname.split('/')[3];
            dispatch(onDownloadInvoicePdf(order_id));
            dispatch(onDownloadPackingListPdf(order_id));
            alert('Order Shipped');
        }

        if (isOrderShippedFalse) alert('Fail to ship order');
        setIsLoading(false);
    }, [dispatch, history, location, isOrderShippedSuccess, isOrderShippedFalse, invoice]);

    useEffect(() => {
        if (isInvoicePdfDownloaded && isPackingListPdfDownloaded) {
            console.log('Download files')
            let order_id = location.pathname.split('/')[3];
            downloadFiles(invoicePdf, `Invoice ${order_id}.pdf`, 'application/pdf');
            downloadFiles(packingListPdf, `Packing List ${order_id}.pdf`, 'application/pdf');
            history.push("/warehouse/dispatch");
        }
    }, [isInvoicePdfDownloaded, invoicePdf, isPackingListPdfDownloaded, packingListPdf])

    useEffect(() => {
        if (isOrderStatusChangeSuccess) {
            alert('Order status changed')
            history.push("/warehouse/dispatch");
        }

        if (isOrderStatusChangeFail) alert('Order failed to cancel');
    }, [history, isOrderStatusChangeSuccess, isOrderStatusChangeFail])

    function handleCancel() {
        dispatch(onPostCancelOrder(invoice.order_id))
    }

    function handleShipOrder() {
        setIsLoading(true);
        dispatch(onPostShipOrder({ order_id: invoice.order_id, ship_method: invoice.ship_method, items: shippedItems, carrier: carrier, tracking_number: trackingNumber, is_promotion: invoice.is_promotion, credit_terms: invoice.credit_terms }));
    }

    function changeItemShippedQty(index, shippedLine) {
        let temp = shippedItems;
        temp[index] = shippedLine;
        setShippedItems(temp);
    }

    if ((typeof invoiceError === 'string' && invoiceError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_order_history) return <Redirect to="/not-authorized" />

    for (let i = 0; i < numRows; i++) {
        rows[i] = <DispatchDetailsTableRows key={i} index={i} item={invoiceArr[i]} userProfile={userProfile} invoiceStatus={invoice.status} changeItemShippedQty={changeItemShippedQty} />
    }

    document.title = `${location.pathname.split('/')[3]} | Order History | Max Advanced Brakes - Prozone`;
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Order History Details" pageTitle="Order History" linkTo={"/warhouse/dispatch"} />
                <Prompt
                    when={isBlocking}
                    message={'Are you sure you want to leave the page?'}
                />
                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo" color={'dark'} outline>
                            <CardHeader className="border-dark border-bottom-dashed p-4">
                                {(isInvoiceDetailsSuccess && invoice && Object.keys(invoice).length) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                            {/* <div className="mt-sm-5 mt-4">
                                                <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                <p className="text-muted mb-1">Ontario, Canada</p>
                                                <p className="text-muted mb-0"><span>Postal Code:</span> L6C3A1</p>
                                            </div> */}
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="margin-top-42">
                                                <Label>Carrier:</Label>
                                                {((invoice.status === 'processing') && (userProfile.access === 9 || userProfile.access === 1)) ? (
                                                    <Input type="text" value={invoice.carrier} onChange={(e) => setCarrier(e.target.value)} />
                                                ) : (null)}
                                            </div>
                                        </div>
                                        <div className="flex-grow-1"></div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal">Invoice No:</span>{" "}
                                                <span id="legal-register-no">{invoice.order_id}</span>
                                            </h6>
                                            <div className="margin-top-34">
                                                <Label>Tracking Number:</Label>
                                                {((invoice.status === 'processing') && (userProfile.access === 9 || userProfile.access === 1)) ? (
                                                    <Input type="text" value={invoice.tracking_number} onChange={(e) => setTrackingNumber(e.target.value)} />
                                                ) : (null)}
                                            </div>
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isInvoiceDetailsSuccess && invoice && Object.keys(invoice).length) ? (    
                                    <Row className="g-3">
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{invoice.order_id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">PO</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{invoice.po}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{invoice.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Shipped Status</p>
                                            <span className="badge badge-soft-primary fs-11">{(invoice.status) ? (invoice.status) : (null)}</span>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                                            <h5 className="fs-14 mb-0">
                                                $<span id="total-amount">{invoice.cost_final}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            {(userProfile.access !== 5 && invoice.is_promotion) ? (
                                                <>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">Promotion Order</p>
                                                    <h5 className="fs-14 mb-0">
                                                        <span id="total-amount">Promotion Order</span>
                                                    </h5>
                                                </>
                                            ) : (null)}
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-dark border-top border-top-dashed">
                                <Row className="g-3">
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3"> Shipping Address </h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.company_name}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1"> {userInfo.state}, {userInfo.country} - {userInfo.zip} </p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="pb-0">
                                <div className="table-responsive">
                                    <Table key={tableInstance} className="table-borderless text-center table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>#</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">MAX-Product</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Quantity</th>
                                                {(userProfile.access !== 5) ? (<th scope="col">Availability</th>) : (null)}
                                                <th scope="col">Shipped</th>
                                                <th scope="col" className="noPrint">Returned</th>
                                                <th scope="col" className="noPrint">Price</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col" className="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                            <tr>
                                                <td colSpan={4}>Total:</td>
                                                <td className="text-center">{invoiceArr ? invoiceArr.reduce((total, curr) => total + curr.qty, 0) : 0}</td>
                                                <td colSpan={1}></td>
                                                <td>{invoiceArr ? invoiceArr.reduce((total, curr) => total + curr.qty_shipped, 0) : 0}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                            <CardBody className="p-4">
                                <Row>
                                    <Col></Col>
                                    <Col md={3} lg={3}>
                                        <div className="table-responsive">
                                            <Table className="table-borderless table-nowrap mb-0">
                                                <tbody className="fs-5">
                                                    {(isInvoiceDetailsSuccess && invoice && Object.keys(invoice).length) ? (
                                                        <>
                                                            <tr>
                                                                <td>Subtotal:</td>
                                                                <td className="text-end">${invoice.cost_subtotal}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tax ({provincesTaxOptions.filter((item) => item.value === userInfo.state)[0]?.label}):</td>
                                                                <td className="text-end">${(Number(invoice.cost_final) - Number(invoice.cost_subtotal)).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border-top border-top-dashed border-dark"><strong>Total:</strong></td>
                                                                <td className="text-end border-top border-top-dashed border-dark">${invoice.cost_final}</td>
                                                            </tr>
                                                        </>
                                                    ) : (null)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4">
                                    <div className="alert alert-primary">
                                        <span className="fw-semibold">Terms And Conditions:</span>
                                        <ul>
                                            <li>All shipment discrepancies must be reported within 5 days from shipped date.</li>
                                            <li>All unauthorized goods return will be applied with 15% administrative fee.</li>
                                            <li>For New Return, 10% restocking charge may be applied and a 10% repackaging fee will be applied if the packaging of the returned goods is not in resalable condition.</li>
                                            <li>For full details, please refer to Max Advanced Brakes <Link to="/returnpolicy">Return Goods Policy</Link>.</li>
                                        </ul>
                                        <p className="mb-0">
                                            <span className="fw-semibold">My Notes:</span> {invoice.my_notes}
                                        </p>
                                        <br />
                                        <p className="mb-0">
                                            <span className="fw-semibold">Notes to Max Brakes:</span> {invoice.notes_to_max_brakes}
                                        </p>
                                    </div>
                                </div>
                                <div className="hstack gap-2 d-print-none mt-4">
                                    {(isLoading) ? (
                                        <Loader />
                                    ) : (
                                        ((userProfile.access === 9 || userProfile.access === 1) && invoice.status === 'processing') ? (
                                            <>
                                                <div >
                                                    <Button className="justify-content-start" color={'secondary'} onClick={handleCancel}>Cancel</Button>
                                                </div>
                                                <div className="m-auto">
                                                    <Button color={'max'} onClick={handleShipOrder}>Ship Order</Button>
                                                </div>
                                            </>
                                        ) : (null)
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DispatchDetails;
