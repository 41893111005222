import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";

import { 
    getCustomerInformation as onGetCustomerInformation,
    getReturnAdjustment as onGetReturnAdjustment,
    getReturnAdjustmentDetails as onGetReturnAdjustmentDetails,
    updateReturnAdjustmentItemsApproval as onUpdateReturnAdjustmentItemsApproval
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

function ReturnAdjustmentDetails() {
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [returnInfo, setReturnInfo] = useState({});
    const [returnAdjustmentDetailsArr, setReturnAdjustmentDetailsArr] = useState([]);

    const { returnAdjustment, isReturnAdjustmentSuccess, returnAdjustmentDetails, isReturnAdjustmentDetailsSuccess, isUpdateReturnAdjustmentItemsApprovalSuccess, isUpdateReturnAdjustmentItemsApprovalFail, userInfo, isUserInfoSuccess, returnsError, customerError } = useSelector((state) => ({
        returnAdjustment: state.Returns.returnAdjustment,
        isReturnAdjustmentSuccess: state.Returns.isReturnAdjustmentSuccess,
        returnAdjustmentDetails: state.Returns.returnAdjustmentDetails,
        isReturnAdjustmentDetailsSuccess: state.Returns.isReturnAdjustmentDetailsSuccess,
        isUpdateReturnAdjustmentItemsApprovalSuccess: state.Returns.isUpdateReturnAdjustmentItemsApprovalSuccess,
        isUpdateReturnAdjustmentItemsApprovalFail: state.Returns.isUpdateReturnAdjustmentItemsApprovalFail,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        returnsError: state.Returns.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let return_adjustment_id = location.pathname.split('/')[3];
        dispatch(onGetReturnAdjustment(return_adjustment_id));
        dispatch(onGetReturnAdjustmentDetails(return_adjustment_id));
    }, [dispatch, location])

    useEffect(() => {
        if (returnAdjustmentDetails && returnAdjustmentDetails.length !== 0) {
            setReturnInfo(returnAdjustment);
            setReturnAdjustmentDetailsArr(returnAdjustmentDetails);
            dispatch(onGetCustomerInformation(returnAdjustment.username));
        }
    }, [dispatch, returnAdjustmentDetails, returnAdjustment])

    useEffect(() => {
        if (isUpdateReturnAdjustmentItemsApprovalSuccess) {
            alert('Return adjustment approved');
            history.push('/order-desk/return-adjustment');
        }

        if (isUpdateReturnAdjustmentItemsApprovalFail) alert('Return adjustment failed status change');
    }, [history, isUpdateReturnAdjustmentItemsApprovalSuccess, isUpdateReturnAdjustmentItemsApprovalFail])

    function handlePriceChange(field, value, i) {
        let temp = returnAdjustmentDetailsArr;
        temp = temp.map((item, index) => {
            if (index === i) {
                item[field] = value;
            }
            return item;
        });
        setReturnAdjustmentDetailsArr(temp);  
    }

    function handleApproval(approval) {
        let unitPriceCheck = returnAdjustmentDetailsArr.every((item) => item.unit_price);

        if (unitPriceCheck || approval === 'rejected') {
            dispatch(onUpdateReturnAdjustmentItemsApproval(returnAdjustmentDetailsArr, approval, returnInfo.id));
        } else {
            alert("Some or all items do not have a unit price.");
        }
    }

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    if ((typeof returnsError === 'string' && returnsError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | Return Adjustment | Max Advanced Brakes - Prozone`;
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Return Adjustment Details" pageTitle="Return Adjustment" linkTo={`/order-desk/return-adjustment`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4">
                                {(isReturnAdjustmentSuccess && Object.keys(returnInfo).length !== 0) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                            {/* <div className="mt-sm-5 mt-4">
                                                <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                <p className="text-muted mb-1">Ontario, Canada</p>
                                                <p className="text-muted mb-0"><span>Postal Code:</span> L6C3A1</p>
                                            </div> */}
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal">
                                                    Return Adjustment No:
                                                </span>{" "}
                                                <span id="legal-register-no">{returnInfo.id}</span>
                                            </h6>
                                            {/* <h6>
                                                <span className="text-muted fw-normal">Email:</span>{" "}
                                                <span id="email">velzon@themesbrand.com</span>
                                            </h6> */}
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isReturnAdjustmentSuccess && Object.keys(returnInfo).length !== 0) ? (    
                                    <Row className="g-3">
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Return No </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{returnInfo.id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Reference Return Invoice </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{returnInfo.return_invoice_id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Date </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{returnInfo.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            {/* <p className="text-muted mb-2 text-uppercase fw-semibold">Status</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{returnInfo.status}</span>
                                            </h5> */}
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed">
                                <Row className="g-3">
                                    {/* <Col sm={6}>
                                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                            Billing Address
                                        </h6>
                                        <p className="fw-medium mb-2">David Nichols</p>
                                        <p className="text-muted mb-1">305 S San Gabriel Blvd</p>
                                        <p className="text-muted mb-1">
                                            California, United States - 91776
                                        </p>
                                        <p className="text-muted mb-1"><span>Phone: +</span><span id="billing-phone-no">(123) 456-7890</span></p>
                                        <p className="text-muted mb-0"><span>Tax: </span><span id="billing-tax-no">12-3456789</span></p>
                                    </Col> */}
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length !== 0) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                    Shipping Address
                                                </h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.username}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1">
                                                    {userInfo.state}, {userInfo.country} - {userInfo.zip}
                                                </p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4">
                                <div className="table-responsive">
                                    <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>#</th>
                                                <th scope="col">Product</th>
                                                {/* <th scope="col">Order Id</th> */}
                                                {/* <th scope="col">Order Date</th> */}
                                                <th scope="col">Returned</th>
                                                <th scope="col">Description</th>
                                                {/* <th scope="col">Unit Price</th> */}
                                                {/* <th scope="col">Reason</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isReturnAdjustmentDetailsSuccess && returnAdjustmentDetailsArr.length !== 0) ? (
                                                returnAdjustmentDetailsArr.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td className="text-center">
                                                                <span className="fw-medium">
                                                                    {item.product}
                                                                </span>
                                                            </td>
                                                            {/* <td>{item.order_id}</td> */}
                                                            {/* <td>{item.order_date_created?.slice(0, 10)}</td> */}
                                                            <td>{item.qty}</td>
                                                            <td>{item.description}</td>
                                                            {/* <td>
                                                                {(returnInfo.status === 'pending') ? (
                                                                    <Input type={'number'} value={item.unit_price} onChange={(e) => handlePriceChange('unit_price', e.target.value, index)} />
                                                                ) : ('$' + item.unit_price)}
                                                            </td> */}
                                                            {/* <td>
                                                                {(returnInfo.status === 'pending') ? (
                                                                    <Input type={'text'} onChange={(e) => handlePriceChange('reason', e.target.value, index)} />
                                                                ) : (item.reason)}
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })
                                            ): (null)}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    {/* {((userProfile.access === 1 || userProfile.access === 3) && returnInfo.status === 'pending') ? (
                                        <>
                                            <Button color={'success'} onClick={() => handleApproval('approved')}>Approve</Button>
                                            <Button color={'danger'} onClick={() => handleApproval('rejected')}>Reject</Button>
                                        </>
                                    ) : (null)} */}
                                    {/* <Link to="#" onClick={printInvoice} className="btn btn-soft-primary">
                                        <i className="ri-printer-line align-bottom me-1"></i> Print
                                    </Link>
                                    <Link to="#" className="btn btn-primary">
                                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                                        Download
                                    </Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReturnAdjustmentDetails;
